import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import { PaginationProps } from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'
import { FetchHubsResponse } from '../types'

export const preloadHub = async (
  pagination = {
    limit: 20,
    offset: 0,
    sort: 'desc',
    query: '',
  } as PaginationProps,
  withAccountData = true,
) => {
  void fetchHubs({ pagination, withAccountData })
}

export const fetchHubs = cache(
  async ({
    pagination = {
      limit: 5,
      offset: 0,
      sort: 'desc',
      query: '',
    } as PaginationProps,
    withAccountData = true,
  }: {
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()
    const hubsResponse = await Nina.Hub.fetchAll(pagination, withAccountData)

    return hubsResponse as FetchHubsResponse
  },
)
