import axios from 'axios'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, PaginationProps } from '../types'
import mapTypeToResponse from '../utils/mapTypeToResponse'

type FetchAllForTagProps = {
  publicKey: string
  pagination: PaginationProps
}

export const fetchAllForTag = cache(
  async ({
    publicKey,
    pagination = {
      limit: 100,
      offset: 0,
      sort: 'desc',
      query: '',
    },
  }: FetchAllForTagProps) => {
    try {
      await initSdk()

      const response = await axios.get(
        `${process.env.NINA_API_ENDPOINT}/tags/${publicKey}`,
        {
          params: pagination,
        },
      )

      const { data } = response
      const tags = data
      tags.releases = mapTypeToResponse(tags.releases, ContentNodeType.Release)

      tags.posts = mapTypeToResponse(tags.posts, ContentNodeType.Post)

      return data
    } catch (error) {
      console.warn(error)

      return { all: [], releases: [], posts: [] }
    }
  },
)
