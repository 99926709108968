import axios from 'axios'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, PaginationProps } from '../types'
import mapTypeToResponse from '../utils/mapTypeToResponse'

export const fetchSearchResults = cache(
  async ({
    pagination = {
      limit: 5,
      offset: 0,
      sort: 'desc',
      query: '',
    },
  }: {
    pagination: PaginationProps
  }) => {
    if (!pagination.query) return

    // TO DO: releases need to return hubs
    // TO DO: add pagination

    try {
      await initSdk()

      const searchResults = (
        await axios.post(`${process.env.NINA_API_ENDPOINT}/search/v2`, {
          ...pagination,
        })
      ).data

      searchResults.accounts = mapTypeToResponse(
        searchResults.accounts,
        ContentNodeType.Account,
      )

      searchResults.hubs = mapTypeToResponse(
        searchResults.hubs,
        ContentNodeType.Hub,
      )
      searchResults.releases = mapTypeToResponse(
        searchResults.releases,
        ContentNodeType.Release,
      )

      searchResults.posts = mapTypeToResponse(
        searchResults.posts,
        ContentNodeType.Post,
      )

      return searchResults
    } catch (error) {
      console.warn(error)

      return { accounts: [], all: [], hubs: [], releases: [], posts: [] }
    }
  },
)
