'use client'

import clsx from 'clsx'
import {
  parseAsInteger,
  parseAsString,
  useQueryState,
} from 'next-usequerystate'
import { useRouter } from 'next/navigation'
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import AccountEmptyContentModal from '@/app/(main)/profiles/[accountPublicKey]/components/AccountEmptyContentModal'
import PaginationPage from '@/components/Pagination'
import PostGridItem from '@/components/PostGridItem'
import ReleaseGridItem from '@/components/ReleaseGridItem'
import Update from '@/contexts/UpdateContext'
import { fetchAccounts } from '@/lib/account/fetchAccounts'
import { fetchFavoritesForAccount } from '@/lib/favorite/fetchFavoritesForAccount'
import { fetchAllForHub } from '@/lib/hub/fetchAllForHub'
import { fetchHubs } from '@/lib/hub/fetchHubs'
import { fetchHubsForAccount } from '@/lib/hub/fetchHubsForAccount'
import { fetchPendingForUser } from '@/lib/pending/fetchPendingForUser'
import { fetchPlayHistory } from '@/lib/play/fetchPlayHistory'
import { fetchPosts } from '@/lib/post/fetchPosts'
import { fetchPostsForAccount } from '@/lib/post/fetchPostsForAccount'
import { fetchPostsForHub } from '@/lib/post/fetchPostsForHub'
import { fetchReleases } from '@/lib/release/fetchReleases'
import { fetchReleasesCollectedByAccount } from '@/lib/release/fetchReleasesCollectedByAccount'
import { fetchReleasesForHub } from '@/lib/release/fetchReleasesForHub'
import { fetchReleasesPublishedByAccount } from '@/lib/release/fetchReleasesPublishedByAccount'
import { fetchSearchResults } from '@/lib/search/fetchSearchResults'
import { fetchAllForTag } from '@/lib/tags/fetchAllForTag'
import { fetchTags } from '@/lib/tags/fetchTags'
import {
  Account,
  AccountHeaderTotals,
  AllContentData,
  ButtonColor,
  ButtonFont,
  ButtonVariant,
  ContentNodeType,
  Hub,
  Post,
  PublicKeyString,
  Release,
  TabbedPageURLSearchParams,
  TagResponse,
  View,
} from '@/lib/types'
import { filterBlockedPosts } from '@/lib/utils/blockedPosts'
import checkIfSafari from '@/lib/utils/checkIfSafari'
import AccountListItem from './AccountListItem'
import HubGridItem from './HubGridItem'
import HubListItem from './HubListItem'
import LoadingComponentAnimated from './LoadingComponent'
import PostListItem from './PostListItem'
import ReleaseListItem from './ReleaseListItem'
import TagListItem from './TagListItem'
import Button from './tokens/Button'
import LoadingComponentStatic from './tokens/LoadingComponentStatic'

export default function ContentPage({
  publicKey,
  searchParams,
  type,
  scrollToRef,
  view,
  shouldPreload = true,
  searchQuery,
  overrideEmptyQuery = false,
  setHeaderTotals,
  isDashboard,
  isHubAuthority = false,
  hubPublicKey = undefined,
}: {
  publicKey?: PublicKeyString
  searchParams: TabbedPageURLSearchParams
  type:
    | 'allReleases'
    | 'accountPublished'
    | 'accountCollection'
    | 'accountHubs'
    | 'accountArticles'
    | 'accountScheduled'
    | 'hubAll'
    | 'hubReleases'
    | 'hubArticles'
    | 'accountFavorites'
    | 'accountHistory'
    | 'searchPosts'
    | 'searchHubs'
    | 'searchAccounts'
    | 'searchAll'
    | 'searchReleases'
    | 'tagsAll'
    | 'tagsReleases'
    | 'tagsArticles'
    | 'searchTags'
  scrollToRef: React.RefObject<null | HTMLDivElement>
  view: View
  shouldPreload?: boolean
  isDashboard?: boolean
  isHubAuthority?: boolean
  hubPublicKey?: PublicKeyString
  searchQuery?: string
  overrideEmptyQuery?: boolean
  setHeaderTotals?: React.Dispatch<React.SetStateAction<AccountHeaderTotals>>
}) {
  const PAGE_SIZE = 20
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [pages, setPages] = useState<{ [key: number]: AllContentData[] }>([])
  const [fetchPending, setFetchPending] = useState<boolean>(true)
  const { date } = useContext(Update.Context)
  const [isSafari, setIsSafari] = useState<boolean | undefined>()
  useEffect(() => {
    if (typeof window !== undefined) {
      setIsSafari(checkIfSafari())
    }
  }, [])

  const [LoadingComponent, setLoadingComponent] = useState<JSX.Element | null>(
    LoadingComponentStatic,
  )

  const [sort] = useQueryState(
    'sort',
    parseAsString.withDefault(
      type === 'searchAccounts' ? 'asc' : searchParams.sort,
    ),
  )

  const [column] = useQueryState(
    'column',
    parseAsString.withDefault(searchParams.column),
  )

  const [query] = useQueryState(
    'query',
    parseAsString.withDefault(searchParams.query || searchQuery || ''),
  )

  const [currentPage, setCurrentPage] = useQueryState<number>(
    'page',
    parseAsInteger.withDefault(1),
  )

  const [Component, setComponent] = useState<JSX.Element | null>(null)
  const router = useRouter()

  const fetchProperties = useMemo(() => {
    switch (type) {
      case 'accountPublished':
        return {
          fetch: fetchReleasesPublishedByAccount,
          property: 'published',
          emptyStateMessage: `This user hasn't published any releases.`,
          emptyProfileStateCta: (
            <>
              <p className="body-1 w-full pb-12">{`You haven't published any releases.`}</p>
              <AccountEmptyContentModal label="Publish release" type={type} />
            </>
          ),
        }
      case 'accountCollection':
        return {
          fetch: fetchReleasesCollectedByAccount,
          property: 'collected',
          emptyStateMessage: `This user hasn't collected any releases.`,
          emptyProfileStateCta: `You haven't collected any releases.`,
        }
      case 'allReleases':
        return {
          fetch: fetchReleases,
          property: 'releases',
          emptyStateMessage: 'No releases found',
        }
      case 'hubReleases':
        return {
          fetch: fetchReleasesForHub,
          property: 'releases',
          emptyStateMessage: 'This hub has no releases.',
        }
      case 'accountArticles':
        return {
          fetch: fetchPostsForAccount,
          property: 'posts',
          emptyStateMessage: `This user hasn't published any articles.`,
          emptyProfileStateCta: (
            <>
              <p className="body-1 w-full pb-12">{`You haven't published any articles.`}</p>
              <Button
                label="Publish article (coming soon)"
                font={ButtonFont.Body1}
                color={ButtonColor.Solid}
                variant={ButtonVariant.Small}
                disabled={true}
                onClick={() => {
                  router.push('/articles/create')
                }}
              />
            </>
          ),
        }
      case 'hubArticles':
        return {
          fetch: fetchPostsForHub,
          property: 'posts',
          emptyStateMessage: 'This hub has no articles.',
        }
      case 'accountHubs':
        return {
          fetch: fetchHubsForAccount,
          property: 'hubs',
          emptyStateMessage: `This user hasn't created any hubs.`,
          emptyProfileStateCta: (
            <>
              <p className="body-1 w-full pb-12">{`You haven't created any hubs.`}</p>
              <AccountEmptyContentModal label="Create hub" type={type} />
            </>
          ),
        }
      case 'hubAll':
        return {
          fetch: fetchAllForHub,
          property: 'all',
          emptyStateMessage: 'This hub is empty.',
        }
      case 'accountFavorites':
        return {
          fetch: fetchFavoritesForAccount,
          property: 'favorites',
          emptyStateMessage: `This user hasn't added any favorites.`,
          emptyProfileStateCta: `You haven't added any favorites.`,
        }
      case 'accountHistory':
        return {
          fetch: fetchPlayHistory,
          property: 'plays',
          emptyStateMessage: 'No history yet',
          emptyProfileStateCta: 'You have no history yet.',
        }
      case 'searchAccounts':
        return {
          fetch: fetchAccounts,
          property: 'accounts',
          emptyStateMessage: `No results found for “${query}”`,
        }
      case 'searchAll':
        return {
          fetch: fetchSearchResults,
          property: 'all',
          emptyStateMessage: `No results found for “${query}”`,
        }
      case 'searchHubs':
        return {
          fetch: fetchHubs,
          property: 'hubs',
          emptyStateMessage: `No results found for “${query}”`,
        }
      case 'searchPosts':
        return {
          fetch: fetchPosts,
          property: 'posts',
          emptyStateMessage: `No results found for “${query}”`,
        }
      case 'searchReleases':
        return {
          fetch: fetchReleases,
          property: 'releases',
          emptyStateMessage: `No results found for “${query}”`,
        }
      case 'accountScheduled':
        return {
          fetch: fetchPendingForUser,
          property: 'pending',
          emptyStateMessage: 'No scheduled releases or posts',
          emptyProfileStateCta: 'You have no scheduled releases or posts.',
        }

      case 'tagsAll':
        return {
          fetch: fetchAllForTag,
          property: 'all',
          emptyStateMessage: 'No results found for this tag.',
        }
      case 'tagsReleases':
        return {
          fetch: fetchAllForTag,
          property: 'releases',
          emptyStateMessage: 'No releases found for this tag.',
        }
      case 'tagsArticles':
        return {
          fetch: fetchAllForTag,
          property: 'posts',
          emptyStateMessage: 'No articles found for this tag.',
        }
      case 'searchTags':
        return {
          fetch: fetchTags,
          property: 'results',
          emptyStateMessage: 'No results found for this tag.',
        }
    }
  }, [type, query])

  const ReleaseItemView = (key: string, release: Release) => {
    switch (view) {
      case View.Grid:
        return (
          <ReleaseGridItem
            key={key}
            release={release}
            isHubAuthority={isHubAuthority}
            hubPublicKey={hubPublicKey}
          />
        )
      case View.List:
        return (
          <ReleaseListItem
            key={key}
            release={release}
            isHubAuthority={isHubAuthority}
            hubPublicKey={hubPublicKey}
          />
        )
    }
  }

  const PostItemView = (key: string, post: Post) => {
    switch (view) {
      case View.Grid:
        return <PostGridItem key={key} post={post} />
      case View.List:
        return <PostListItem key={key} post={post} />
    }
  }

  const HubItemView = (key: string, hub: Hub) => {
    switch (view) {
      case View.Grid:
        return <HubGridItem key={key} hub={hub} />
      case View.List:
        return <HubListItem key={key} hub={hub} inContentPage={true} />
    }
  }

  const TagItemView = (key: string, tag: TagResponse) => {
    return <TagListItem key={key} tag={tag} />
  }

  const fetchContent = useCallback(async () => {
    setFetchPending(true)
    const { fetch, property } = fetchProperties

    const response = await fetch({
      publicKey: publicKey || '',
      pagination: {
        limit: PAGE_SIZE,
        offset: currentPage ? (currentPage - 1) * PAGE_SIZE : 0,
        sort: sort || 'desc',
        column: column || 'datetime',
        query: query || '',
      },
      withAccountData: false,
    })

    if (response) {
      if (response.posts) {
        response.posts = filterBlockedPosts(response.posts)
      }

      const totalPagesTemp = Math.ceil(response.total / PAGE_SIZE)
      setTotal(response.total)
      setTotalPages(totalPagesTemp)
      setPages({ [currentPage]: response[property] })
      pagePreloadHandler(currentPage, totalPagesTemp)

      if (setHeaderTotals) {
        setHeaderTotals((prevState) => ({
          ...prevState,
          [property]: response.total,
        }))
      }
    }

    setFetchPending(false)
  }, [currentPage, column, sort, query])

  useEffect(() => {
    if (hubPublicKey) {
      fetchContent()
    }
  }, [date])

  useLayoutEffect(() => {
    if (overrideEmptyQuery) {
      fetchContent()
    }
  }, [overrideEmptyQuery])

  useLayoutEffect(() => {
    fetchContent()
  }, [fetchContent])

  const pagePreloadHandler = async (page: number, totalPages: number) => {
    if (!shouldPreload) {
      return
    }

    const { fetch, property } = fetchProperties

    // This is a fix for case when ContentPage initializes and for some reason the first page is not fetched
    // symptom of another problem, but this is a quick fix
    if (!pages[1]) {
      const response = await fetch({
        publicKey: publicKey || '',
        pagination: {
          limit: PAGE_SIZE,
          offset: 0,
          sort: 'desc',
          query,
        },
        withAccountData: false,
      })

      if (response) {
        setPages((prevState) => ({
          ...prevState,
          [1]: response[property],
        }))
      }
    }

    if (!pages[page - 1] && page > 1) {
      const response = await fetch({
        publicKey: publicKey || '',
        pagination: {
          limit: PAGE_SIZE,
          offset: (page - 2) * PAGE_SIZE,
          sort: 'desc',
          query,
        },
        withAccountData: false,
      })

      if (response) {
        setPages((prevState) => ({
          ...prevState,
          [page - 1]: response[property],
        }))
      }
    }

    if (!pages[page + 1] && page < totalPages - 1) {
      const response = await fetch({
        publicKey: publicKey || '',
        pagination: {
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
          sort: 'desc',
          query,
        },
        withAccountData: false,
      })

      if (response) {
        setPages((prevState) => ({
          ...prevState,
          [page + 1]: response[property],
        }))
      }
    }
  }

  const handlePageChange = async (page: number) => {
    const { fetch, property } = fetchProperties

    if (!pages[page]) {
      setFetchPending(true)
      scrollToRef.current?.scrollIntoView({
        behavior: 'instant' as ScrollBehavior,
      })
      try {
        const response = await fetch({
          publicKey: publicKey || '',
          pagination: {
            limit: PAGE_SIZE,
            offset: (page - 1) * PAGE_SIZE,
            sort: 'desc',
            query,
          },
          withAccountData: false,
        })

        if (response) {
          setPages((prevState) => ({
            ...prevState,
            [page]: response[property],
          }))
          setFetchPending(false)
        }
      } catch (error) {
        console.warn('handlePageChange:', error)
      }
    } else {
      scrollToRef.current?.scrollIntoView({
        behavior: 'instant' as ScrollBehavior,
      })
    }

    setCurrentPage(page)
    pagePreloadHandler(page, totalPages)
  }

  useLayoutEffect(() => {
    setComponent(
      <>
        {pages[currentPage]?.map((item, index) => {
          switch (item.type) {
            case ContentNodeType.Account:
              return (
                <li className="border-b-1 border-grey-10 last-of-type:border-none">
                  <AccountListItem
                    key={`${item.publicKey}-${index}`}
                    item={item as Account}
                  />
                </li>
              )
            case ContentNodeType.Release:
            case ContentNodeType.HubRelease:
              return ReleaseItemView(
                `${item.publicKey}-${index}`,
                item as Release,
              )
            case ContentNodeType.Post:
            case ContentNodeType.HubPost:
              return PostItemView(item.publicKey, item as Post)
            case ContentNodeType.Hub:
              return HubItemView(item.publicKey, item as Hub)
            case ContentNodeType.Tags:
              return TagItemView(index.toString(), item as TagResponse)
            default:
              return null
          }
        })}
      </>,
    )
  }, [pages, currentPage, view])

  useEffect(() => {
    let LoadingComponentTemp = null

    if (fetchPending === null || fetchPending) {
      if (type.includes('search')) {
        LoadingComponentTemp = LoadingComponentAnimated('Searching Nina')
      } else {
        if (query.length > 0) {
          LoadingComponentTemp = LoadingComponentAnimated(
            `Searching for ${query}`,
          )
        } else {
          LoadingComponentTemp = LoadingComponentAnimated('Loading')
        }
      }
    }

    setLoadingComponent(LoadingComponentTemp)
  }, [fetchPending, query])

  return (
    <>
      {fetchPending && LoadingComponent}
      {view === View.Grid && (
        // grid with a height that is not fixed (i.e. h-full) needs to be wrapped in a block element with hidden overflow for safari to understand
        // safari cannot process when a height is '100%'
        <div className="grid">
          {!fetchPending && !total && query.length > 0 && (
            <>
              <p className="body-1 text-grey-45">{`No results found for “${query}”`}</p>
            </>
          )}
          {!fetchPending && !total && !isDashboard && query.length === 0 && (
            <>
              <p className="body-1 w-full text-grey-45">
                {fetchProperties.emptyStateMessage}
              </p>
            </>
          )}

          {!fetchPending && !total && isDashboard && (
            <>
              <p className="body-1 w-full text-grey-45">
                {fetchProperties.emptyProfileStateCta}
              </p>
            </>
          )}
          <div className="flex overflow-hidden">
            <div
              className={clsx(
                'flex-end grid h-full w-full grid-cols-2 gap-x-12 gap-y-20 sm:gap-y-28 md:grid-cols-4 md:gap-x-20 lg:gap-x-28 xl:grid-cols-5',
                !isSafari && 'items-baseline',
              )}
            >
              {!fetchPending && total > 0 && Component}
            </div>
          </div>
        </div>
      )}

      {view === View.List && (
        <>
          <ul>
            {!fetchPending && pages[currentPage]?.length > 0 && Component}
          </ul>

          {!fetchPending && !total && !isDashboard && (
            <p className="body-1 text-grey-45">
              {fetchProperties.emptyStateMessage}
            </p>
          )}

          {!fetchPending && !total && isDashboard && (
            <>
              <p className="body-1 w-full text-grey-45">
                {fetchProperties.emptyProfileStateCta}
              </p>
            </>
          )}
        </>
      )}

      {totalPages > 1 && (
        <PaginationPage
          total={total}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          pageSize={PAGE_SIZE}
        />
      )}
    </>
  )
}
