import axios from 'axios'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, PaginationProps } from '../types'
import mapTypeToResponse from '../utils/mapTypeToResponse'

export const fetchTags = cache(
  async ({
    pagination = {
      query: '',
      limit: 5,
      offset: 0,
      sort: 'desc',
    } as PaginationProps,
  }: {
    pagination: PaginationProps
  }) => {
    try {
      await initSdk()
      const url = `${process.env.NINA_API_ENDPOINT}/tags`

      const response = await axios.get(url, {
        params: pagination,
      })

      const { data } = response
      const { tags } = data
      tags.results = mapTypeToResponse(tags.results, ContentNodeType.Tags)

      return tags
    } catch (error) {
      console.warn(error)

      return { results: [] }
    }
  },
)
