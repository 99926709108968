import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { AllContent, PaginationProps } from '../types'

export const fetchAllForHub = cache(
  async ({
    publicKey,
    pagination = { limit: 20, offset: 0, sort: 'desc' } as PaginationProps,
    withAccountData = true,
  }: {
    publicKey: string
    pagination: PaginationProps
    withAccountData: boolean
  }): Promise<AllContent> => {
    await initSdk()

    let url = `${process.env.NINA_API_ENDPOINT}/hubs/${publicKey}/all?limit=${pagination.limit}&offset=${pagination.offset}&sort=${pagination.sort}&withAccountData=${withAccountData}`

    if (pagination.query) {
      url += `&query=${pagination.query}`
    }

    if (pagination.column) {
      url += `&column=${pagination.column}`
    }

    const hubsResponse = await fetch(url)

    return hubsResponse.json()
  },
)
