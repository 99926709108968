'use client'

import Icon from './tokens/Icon'

type PaginationSelectProps = {
  totalPages: number
  currentPage: number
  handlePageChange: (page: number) => void
}

export default function PaginationSelect({
  totalPages,
  currentPage,
  handlePageChange,
}: PaginationSelectProps) {
  const Options = Array.from(Array(totalPages).keys())
    .map((i) => i + 1)
    .map((page) => {
      return (
        <option
          key={page}
          value={page}
          selected={currentPage === page ? true : false}
        >
          {`Page ${page} of ${totalPages}`}
        </option>
      )
    })

  return (
    <div className="relative hidden flex-row items-center gap-x-1 sm:flex">
      <select
        className="body-1 appearance-none bg-white bg-opacity-0 pr-20 focus:outline-none"
        onChange={(e) => {
          handlePageChange(parseInt(e.target.value))
        }}
      >
        {Options}
      </select>
      <Icon
        className="black absolute right-0 top-[1px] z-[-1]"
        name="chevronDownHeavy"
        height={20}
        width={20}
      />
    </div>
  )
}
