import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import { PaginationProps } from '@/lib/types'
import { FetchReleasesResponse } from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'

export const preload = async (
  pagination = {
    limit: 20,
    offset: 0,
    sort: 'desc',
    query: '',
  } as PaginationProps,
  withAccountData = true,
) => {
  void fetchReleases({
    pagination,
    withAccountData,
  })
}

export const fetchReleases = cache(
  async ({
    pagination = {
      limit: 5,
      offset: 0,
      sort: 'desc',
      query: '',
    } as PaginationProps,
    withAccountData = true,
  }: {
    pagination: PaginationProps
    withAccountData: boolean
  }) => {
    await initSdk()

    if (pagination.query === ' ') {
      pagination.query = ''
    }

    //NOTE: query not properly being passed
    const releasesResponse = await Nina.Release.fetchAll(
      pagination,
      withAccountData,
    )

    return releasesResponse as FetchReleasesResponse
  },
)
