import Link from 'next/link'
import React, { memo } from 'react'
import { TagResponse } from '@/lib/types'

type TagListItemProps = {
  tag: TagResponse
  key: string
}

function TagListItem({ tag, key }: TagListItemProps) {
  return (
    <li
      className="body-1 group flex w-full justify-between border-b border-grey-15 py-12 last-of-type:border-none"
      key={key}
    >
      <div className="flex h-[40px] items-center">
        <Link
          href={`/tags/${tag.value}`}
          className="body-1 line-clamp-1 overflow-hidden text-blue-82 hover:text-opacity-80"
        >
          #{tag.value}
        </Link>
        <p className="body-1 ml-8 text-grey-45"> {tag.count}</p>
      </div>
    </li>
  )
}

export default memo(TagListItem)
