'use client'

import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { View } from '@/lib/types'
import { FilterType } from '@/lib/types'
import Icon from './tokens/Icon'
import SearchInput from './tokens/SearchInput'
import Select from './tokens/Select'

type FilterProps = {
  toggleView: (view: View) => void
  filterOptions?: FilterObject[]
  view: View | undefined
  handleChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  expandSearchInput: boolean
  setExpandSearchInput: React.Dispatch<React.SetStateAction<boolean>>
  handleClear: (e: React.MouseEvent) => void
  hideSearchFilter?: boolean
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
}

interface FilterObject {
  label: FilterType
  filterValues: { label: string; value: string }[]
}

export default function Filter({
  toggleView,
  filterOptions,
  view,
  handleChangeSelect,
  expandSearchInput,
  setExpandSearchInput,
  hideSearchFilter,
  handleClear,
  query,
  setQuery,
}: FilterProps) {
  const [selected] = useState<{ [key: string]: string }>({})
  const mobileSearchInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (expandSearchInput) {
      mobileSearchInputRef.current?.focus()
    }
  }, [expandSearchInput])

  const handleShowSearchInput = () => {
    setExpandSearchInput(true)
  }

  const sortFilters = (filterOptions: FilterObject[]) => {
    return (
      <>
        {filterOptions.map((filterOption: FilterObject, index: number) => {
          const { filterValues, label } = filterOption

          return (
            <Select
              key={index}
              options={filterValues}
              name={label}
              variant={'filter'}
              label={''}
              value={selected[label]}
              required={false}
              type="select"
              inputProps={undefined}
              handleChange={handleChangeSelect}
              styleOverride="h-[28px]"
            />
          )
        })}
      </>
    )
  }

  const mobileFilter = () => {
    return (
      <div className="flex flex-col md:hidden">
        <div className="flex flex-col gap-y-12">
          {filterOptions && sortFilters(filterOptions)}
        </div>
        <div className="mt-20 flex h-[20px] justify-between  md:hidden">
          <div className="flex items-center gap-x-12  lg:pt-0">
            <abbr title="Grid view" className="flex no-underline">
              <button
                disabled={view === View.Grid}
                onClick={() => toggleView(View.Grid)}
              >
                <Icon
                  className={clsx(
                    view === View.Grid ? 'black' : 'grey-45 hover:opacity-80',
                  )}
                  name="gridView"
                  width={20}
                  height={20}
                />
              </button>
            </abbr>
            <abbr title="List view" className="flex no-underline">
              <button
                disabled={view === View.List}
                onClick={() => toggleView(View.List)}
              >
                <Icon
                  className={clsx(
                    view === View.List ? 'black' : 'grey-45 hover:opacity-80',
                    '',
                  )}
                  name="listView"
                  width={20}
                  height={20}
                />
              </button>
            </abbr>
          </div>
          {!hideSearchFilter && (
            <div className="flex md:hidden">
              {expandSearchInput ? (
                <SearchInput
                  placeholder=""
                  name={'Filter results'}
                  query={query}
                  setQuery={setQuery}
                  disabled={false}
                  handleClear={(e) => handleClear(e)}
                  inSearchPage={false}
                  view="mobile"
                  inputStyleOverride="!h-[20px]"
                  searchInputRef={mobileSearchInputRef}
                />
              ) : (
                <button onClick={() => handleShowSearchInput()}>
                  <Icon
                    className="black cursor-pointer"
                    name="searchLarge"
                    width={20}
                    height={20}
                  />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const desktopFilter = () => {
    return (
      <div className="hidden h-28 flex-row items-center justify-between md:flex">
        <div className="hidden items-center gap-x-12 md:flex">
          <abbr title="Grid view" className="flex no-underline">
            <button
              disabled={view === View.Grid}
              onClick={() => toggleView(View.Grid)}
            >
              <Icon
                className={clsx(
                  view === View.Grid ? 'black' : 'grey-45 hover:opacity-80',
                  'h-20 w-20',
                )}
                name="gridView"
              />
            </button>
          </abbr>
          <abbr title="List view" className="flex no-underline">
            <button
              disabled={view === View.List}
              onClick={() => toggleView(View.List)}
            >
              <Icon
                className={clsx(
                  view === View.List ? 'black' : 'grey-45 hover:opacity-80',
                  'h-18 w-18',
                )}
                name="listView"
              />
            </button>
          </abbr>
        </div>
        <div className="hidden items-center gap-x-12 md:flex">
          {!hideSearchFilter && (
            <div className="flex">
              <SearchInput
                placeholder="Filter results"
                name={'Filter results'}
                query={query}
                setQuery={setQuery}
                disabled={false}
                handleClear={(e) => handleClear(e)}
                inSearchPage={false}
                view="desktop"
              />
            </div>
          )}
          {filterOptions && sortFilters(filterOptions)}
        </div>
      </div>
    )
  }

  return (
    <>
      {mobileFilter()}
      {desktopFilter()}
    </>
  )
}
