import clsx from 'clsx'
import PaginationSelect from './PaginationSelect'

export default function PaginationPage({
  total,
  totalPages,
  currentPage,
  handlePageChange,
  pageSize,
}: {
  total: number
  totalPages: number
  currentPage: number
  handlePageChange: (page: number) => void
  pageSize: number
}) {
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  return (
    <div className="items-left col-span-full mt-20 flex pt-20 sm:mt-28 sm:justify-between">
      <div className="flex w-full flex-row items-center justify-between gap-x-12 sm:w-auto sm:items-start sm:gap-x-28">
        <p className="body-1 flex w-1/2 sm:w-auto">
          {(currentPage - 1) * pageSize + 1} –{' '}
          {currentPage * pageSize > total ? total : currentPage * pageSize} of{' '}
          {total}
        </p>
        <div className="flex w-1/2 flex-row sm:w-auto sm:gap-x-28">
          {currentPage * pageSize >= 20 && (
            <div className="flex flex-row sm:w-auto">
              {currentPage > 1 && (
                <>
                  <div
                    className="hidden cursor-pointer sm:flex"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <p className="body-1">Previous</p>
                  </div>
                  <div className="flex sm:hidden">
                    <button
                      className={clsx(
                        isLastPage ? 'w-[45vw]' : 'mr-12 w-[80px]',
                        'border-grey cursor-pointer border-1 py-4 text-grey-45',
                      )}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <p className="body-1 px-12">Prev</p>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          {currentPage < totalPages && (
            <>
              <div className="sm:flex sm:flex-row">
                <div
                  className="hidden cursor-pointer sm:flex"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <p className="body-1">Next</p>
                </div>
                <div className="flex sm:hidden">
                  <button
                    className={clsx(
                      isFirstPage ? 'w-[45vw]' : 'w-[80px]',
                      'border-grey cursor-pointer border-1 py-4 text-grey-45',
                    )}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <p className="body-1 px-12">Next</p>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <PaginationSelect
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  )
}
