import { ContentNodeType } from '../types'

const mapResults = <T>(results: T[], type: ContentNodeType): T[] => {
  if (results && results.length > 0) {
    return results.map((result) => {
      return { ...result, type }
    })
  }

  return []
}

export default mapResults
