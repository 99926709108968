import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps } from '../types'
import { filterBlockedPosts } from '../utils/blockedPosts'

export const fetchPosts = cache(
  async ({
    pagination = {
      limit: 5,
      offset: 0,
      sort: 'desc',
      query: '',
    } as PaginationProps,
    withAccountData = false,
  }) => {
    await initSdk()

    const posts = await Nina.Post.fetchAll(pagination, withAccountData)
    posts.posts = filterBlockedPosts(posts.posts)

    return posts
  },
)
