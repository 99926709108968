import { PaginationProps } from '../types'
import initSdk from '../utils/initSdk'

export const fetchPendingForUser = async ({
  pagination,
}: {
  pagination?: PaginationProps
}) => {
  await initSdk()
  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    console.warn('No session signature found')

    return
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const pending = await fetch(
    `${process.env.NINA_FILE_SERVICE_ENDPOINT}/pending`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        signature,
        publicKey,
        ...pagination,
      }),
    },
  )

  const pendingJson = await pending.json()

  return pendingJson
}
