'use client'

import { clsx } from 'clsx'
import {
  parseAsInteger,
  parseAsString,
  useQueryState,
} from 'next-usequerystate'
import { usePathname } from 'next/navigation'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import { TabbedPageURLSearchParams, View } from '@/lib/types'
import { FilterType } from '@/lib/types'
import { camelCaseToUi } from '@/lib/utils/camelCaseToUi'
import { logEvent } from '@/lib/utils/event'
import Filter from './Filter'

interface FilterObject {
  label: FilterType
  filterValues: { label: string; value: string }[]
}

export type TabProps = {
  content: object
  searchParams: TabbedPageURLSearchParams
  filterOptions?: FilterObject[]
  scrollToRef: React.RefObject<HTMLDivElement>
  view: View
  setView?: (view: View) => void
  hideSearchFilter?: boolean
  hideTabs?: boolean
  hideSortFilter?: boolean
  contentHeaderTotals?: { [key: string]: number | undefined }
}

function TabsPaginated({
  content,
  searchParams,
  filterOptions,
  scrollToRef,
  view,
  setView,
  hideSearchFilter = false,
  hideSortFilter = false,
  hideTabs = false,
  contentHeaderTotals,
}: TabProps) {
  const headers = useMemo(() => Object.keys(content), [content])
  const contentArray = useMemo(() => Object.values(content), [content])
  const [expandSearchInput, setExpandSearchInput] = useState(false)
  const pathname = usePathname()
  const { wallet } = useContext(Wallet.Context)

  const [activeTab, setActiveTab] = useQueryState(
    'tab',
    parseAsString.withDefault(searchParams.tab),
  )

  useEffect(() => {
    //logic for default activeTab when navigating from searchResults view
    if (headers.indexOf(activeTab) === -1) {
      setActiveTab(headers[0])
    }
  }, [headers, activeTab])

  const [page, setPage] = useQueryState(
    'page',
    parseAsInteger.withDefault(searchParams.page),
  )

  const [sort, setSort] = useQueryState(
    'sort',
    parseAsString.withDefault(searchParams.sort),
  )

  const [column, setColumn] = useQueryState(
    'column',
    parseAsString.withDefault(searchParams.column),
  )

  const [query, setQuery] = useQueryState(
    'query',
    parseAsString.withDefault(searchParams.query),
  )

  const [pageHistory, setPageHistory] = useState({
    [activeTab]: searchParams.page,
  })

  useEffect(() => {
    setPageHistory({ ...pageHistory, [activeTab]: page })
  }, [page])

  const handleClick = (tab: string) => {
    if (pageHistory[tab] !== searchParams.page) {
      setPage(pageHistory[tab] || 1)
    }

    setActiveTab(tab)
    logEvent('tab_clicked', 'interaction', wallet, { tab, pathname })
  }

  const toggleView = (toggledView: View) => {
    setView && setView(toggledView)
  }

  // TO DO - handle columns for hubs/all, hubs/posts, profiles/all, profiles/posts

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target

    if (value === 'recentlyAdded') {
      setSort('desc')
      setColumn('datetime')
      searchParams.sort = sort
      searchParams.column = column
    } else if (value === 'titleAZ') {
      setSort('asc')
      setColumn('metadata:name')
      searchParams.sort = sort
      searchParams.column = column
    } else if (value === 'titleZA') {
      setSort('desc')
      setColumn('metadata:name')
      searchParams.sort = sort
      searchParams.column = column
    } else if (value === 'earliest') {
      setSort('asc')
      setColumn('datetime')
      searchParams.sort = sort
      searchParams.column = column
    }
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { value } = e.target
    setPage(1)
    setQuery(value)
  }

  const handleClear = (e: React.MouseEvent) => {
    e.preventDefault()
    setExpandSearchInput(false)
    setPage(1)
    setQuery('')
  }

  return (
    <div className="flex flex-col" id="tabs" ref={scrollToRef}>
      <div className="relative flex flex-col">
        <div
          className={clsx(
            !hideTabs &&
              'relative mb-20 flex w-full md:mb-28 md:border-b-1 md:border-solid md:border-grey-10',
          )}
        >
          <div className="no-scrollbar absolute bottom-[-1px] ml-[-10px] w-[99vw] overflow-x-auto pl-10 md:ml-0 md:w-full md:pl-0">
            <div className="flex">
              {!hideTabs &&
                headers.map((tab, index) => {
                  let total

                  if (
                    contentHeaderTotals &&
                    contentHeaderTotals[tab] !== undefined
                  ) {
                    total = contentHeaderTotals[tab]!
                  }

                  return (
                    <>
                      <button
                        key={index}
                        onClick={() => handleClick(tab)}
                        className={clsx(
                          activeTab === tab
                            ? 'border-b-1 border-black'
                            : ' border-b-1 border-white md:border-grey-10',
                          'mr-[16px] md:mr-[20px]',
                          index === headers.length - 1 && 'mr-[6px]',
                        )}
                      >
                        <h1
                          key={index}
                          onClick={() => handleClick(tab)}
                          className={clsx(
                            activeTab === tab
                              ? 'pb-[1px] md:hover:!opacity-100'
                              : 'pb-[2px]',
                            'ui-1 whitespace-nowrap pb-[2px]  hover:opacity-70 md:pb-8',
                          )} //this padding bottom may need to be re-written like the other tabs
                        >
                          {camelCaseToUi(tab)}
                          {total !== undefined && ` (${total})`}
                        </h1>
                      </button>
                      {/* forcing white space on right for mobile */}
                      {index === headers.length - 1 && (
                        <div className=" w-[6px] bg-white text-white md:hidden">
                          .
                        </div>
                      )}
                    </>
                  )
                })}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'mb-20 md:mb-28',
            hideSortFilter && 'hidden lg:block',
          )}
        >
          <Filter
            toggleView={toggleView}
            filterOptions={filterOptions}
            view={view}
            handleChangeSelect={(e) => handleChangeSelect(e)}
            handleSearchInput={(e) => handleSearchInput(e)}
            query={query}
            setQuery={setQuery}
            expandSearchInput={expandSearchInput}
            setExpandSearchInput={setExpandSearchInput}
            handleClear={(e) => handleClear(e)}
            hideSearchFilter={hideSearchFilter}
          />
        </div>
        {contentArray.map((content, index) => {
          return (
            <div
              key={index}
              className={clsx(
                activeTab === headers[index] ? 'block' : 'hidden ',
              )}
            >
              {content}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(TabsPaginated)
