import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps } from '../types'

export const fetchAccounts = cache(
  async ({
    pagination = {
      limit: 5,
      offset: 0,
      sort: 'desc',
      query: '',
    } as PaginationProps,
  }) => {
    await initSdk()

    if (pagination.query === ' ') {
      pagination.query = ''
    }

    const accounts = await Nina.Account.fetchAll(pagination)

    return accounts
  },
)
