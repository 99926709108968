'use client'

import { useState } from 'react'
import HubCreate from '@/components/HubCreate'
import Modal from '@/components/Modal'
import ReleaseCreate from '@/components/ReleaseCreate'
import { ButtonColor, ButtonResponsiveness, ButtonVariant } from '@/lib/types'

type AccountEmptyContentModalProps = {
  label: string
  type: string
}

export default function AccountEmptyContentModal({
  label,
  type,
}: AccountEmptyContentModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleEmptyContentModalForm = (type: string) => {
    switch (type) {
      case 'accountPublished':
        return <ReleaseCreate isOpen={isOpen} setIsOpen={setIsOpen} />
      case 'accountHubs':
        return <HubCreate isOpen={isOpen} setIsOpen={setIsOpen} />
      default:
        return null
    }
  }

  return (
    <div>
      <Modal
        label={label}
        modalLabelVariant={ButtonVariant.Small}
        modalLabelColor={ButtonColor.Blue}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalLabelResponsiveness={ButtonResponsiveness.MinW}
      >
        {handleEmptyContentModalForm(type)}
      </Modal>
    </div>
  )
}
