import Link from 'next/link'
import { Post } from '@/lib/types'
import { formatYYYYMMDD } from '@/lib/utils/formatYYYYMMDD'
import ImageWithArweaveFallback from './ImageWithArweaveFallback'

type PostListItemProps = {
  post: Post
}

export default function PostListItem({ post }: PostListItemProps) {
  const imageLink = post.data?.heroImage

  return (
    <li className="body-1 flex justify-between border-b-1 border-grey-10 py-12 last-of-type:border-none">
      <div className="flex items-start pr-12">
        {imageLink && (
          <div className="my-[8.75px] mr-12 h-[22.5px] min-w-[40px]">
            <ImageWithArweaveFallback
              alt={post.data.title}
              className="imageBorder h-[22.5px] w-[40px] object-cover align-middle"
              src={imageLink}
              height={22.5}
              width={40}
            />
          </div>
        )}
        {!imageLink && (
          <div className="imageBorder my-auto mr-12 h-[23px] w-[40px]"></div>
        )}
        <div className="w-auto flex-col">
          <Link
            href={`/articles/${post.data.slug}`}
            className="line-clamp-2 w-fit text-black hover:text-opacity-80"
          >
            {post.data.title}
          </Link>
          {post.hub && (
            <Link
              href={`/hubs/${post.hub.handle}`}
              className="line-clamp-1 w-fit text-blue-82 hover:text-opacity-80"
            >
              {post.hub.data.displayName}
            </Link>
          )}
        </div>
      </div>

      <div className="group flex max-w-[200px] items-start gap-x-12 md:gap-x-28">
        <p className="ui-1-list hidden whitespace-nowrap tabular-nums md:block">
          {formatYYYYMMDD(post.datetime)}
        </p>
        <span className="body-1 hidden w-[46px] text-right sm:block" />
        <span className="w-20" />
        {/* <Icon className="relative cursor-not-allowed grey-45 " name="ellipsis" /> */}
      </div>
    </li>
  )
}
