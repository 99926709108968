import {
  FetchPlayHistoryResponse,
  PaginationProps,
  PlayHistoryItem,
} from '../types'
import initSdk from '../utils/initSdk'

export const fetchPlayHistory = async ({
  pagination,
}: {
  pagination?: PaginationProps
}) => {
  await initSdk()

  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    throw new Error('Session signature not found')
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const playHistory = await fetch(
    `${
      process.env.NINA_ID_ENDPOINT
    }/plays?message=${message}&signature=${signature}&publicKey=${publicKey}&limit=${
      pagination?.limit || 25
    }&offset=${pagination?.offset || 0}`,
  )

  const playHistoryJson = await playHistory.json()
  playHistoryJson.plays = playHistoryJson?.plays?.map(
    (play: PlayHistoryItem) => {
      return {
        ...play,
        ...play.release,
        type: 'release',
      }
    },
  )

  return (playHistoryJson as FetchPlayHistoryResponse) || undefined
}
